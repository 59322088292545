.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  min-width: 200px;
  z-index: 1;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-top: 2px;
  padding: 10px;
  font-size: 13px;
  display: none;
  position: absolute;
  overflow: auto;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-content a {
  color: #000;
  padding: 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: #b58d79;
  font-weight: bold;
}

.dropdown a:hover {
  cursor: pointer;
}

.show {
  display: block;
}

/*# sourceMappingURL=navbar.css.map */
