.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    margin-top: 2px;
    border-radius: 5px;
    padding: 10px;
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 200px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font-size: 13px;
}

.dropdown-content {
    a {
        color: black;
        padding: 5px;
        text-decoration: none;
        display: block;

        &:hover {
            color: #B58D79;
            font-weight: bold;
        }
    }
}

.dropdown a:hover {cursor: pointer}

.show {display: block;}